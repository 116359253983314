import { css } from "@emotion/react"
import Flexbox from "atoms/Flexbox"
import { FlexboxItem } from "atoms/Flexbox/Flexbox"
import ResponsiveDiv from "atoms/ResponsiveDiv"
import Text from "atoms/Text"
import WidgetContainer from "atoms/WidgetContainer"
import Animated from "molecules/Animated"
import { ImpactCardProps } from "./types"
import impactDetails from "constants/impactDetails"
import React from "react"

const styles = {
	ourImpactContainer: css`
	height: 100%;
	border-radius: calc(var(--spacing-md));
	padding: calc(8 * var(--spacing-xs)) calc(var(--spacing-xxl)) calc(8 * var(--spacing-xs)) calc(var(--spacing-xxl));
	border: 1px solid var(--color-gray);
	&:hover {
		background-image: var(--color-primary-gradient);
	}
`,
	impactCard: css`
		border-radius: var(--spacing-md);
		border: 1px solid var(--color-gray);
		overflow: hidden;
		position: relative;
		padding: calc(8 * var(--spacing-xs)) var(--spacing-xxl);
		width: 100%;
		height: 100%;
		cursor: pointer;

		&:hover span {
			opacity: 1;
			z-index: 0;
		}

		&:hover p {
			color: white;
		}
	`,
	impactCardContent: css`
		position: relative;
	`,
	impactCardLayer: css`
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: var(--color-primary-gradient);
		opacity: 0;

		transition: opacity 0.1s linear;
	`
}

const ImpactCard = (props: ImpactCardProps): JSX.Element => {
	return (
		<Animated passedCSS={css`height: 100%;`} animation="zoomIn" duration={0.5} timingFunction="ease" iteration={1} trigger="inview">
			<ResponsiveDiv
				css={styles.impactCard}
			>
				<ResponsiveDiv as="span" css={styles.impactCardLayer} />
				<ResponsiveDiv
					as="div"
					css={styles.impactCardContent}
				>
					<Text as="p" color="white" fontStyleGuide="manrope-heading2" mb="xs">{props.title}</Text>
					<Text as="p" color="gray-neutral" fontStyleGuide="body4" mb="xs">{props.subtitle}</Text>
				</ResponsiveDiv>
			</ResponsiveDiv>
		</Animated>
	)
}

export default () => {
	return (
		<WidgetContainer>
			<Animated animation="fadeInUp" duration={1} timingFunction="ease" iteration={1} trigger="inview">
				<Text fontStyleGuide="heading4" color="white" align="center" mb="2xl">
					Our &nbsp;
					<Text as="span" fontStyleGuide="heading4" primaryGradient={true}>Impact</Text>
				</Text>
			</Animated>
			<ResponsiveDiv
				xl={css`
					article {
						margin-bottom: 24px;
					}
				`}
			>
				<Flexbox
					direction="row"
					justify="space-between"
					align="stretch"
					as="section"
				>
					{impactDetails.slice(0, 3).map((impact) => (
						<FlexboxItem as={"article"} colspan={24} colspanXL={7.7} key={impact.title} mb="sm">
							<ImpactCard {...impact} />
						</FlexboxItem>
					))}
				</Flexbox>
				<Flexbox as="section" direction="row" justify="space-between" align="center">
					{impactDetails.slice(3, impactDetails.length).map((impact) => (
						<FlexboxItem as="article" colspan={24} colspanXL={5.7} key={impact.title} mb="sm">
							<ImpactCard {...impact} />
						</FlexboxItem>
					))}
				</Flexbox>
			</ResponsiveDiv>
		</WidgetContainer>
	)

}
