export default [
	{
		company_name: "Fashion Jewel",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/fashion_jewel.png",
		subtitle: "",
		content: "Before Shopdeck, we struggled with establishing an online presence and missed out on digital opportunities. Shopdeck’s platform not only helped us launch into the online space but also provided a seamless way to manage our listings and sales performance. Their low commission structure ensured profitability right from the start. Since we started using Shopdeck, we’ve seen significant growth in our online sales and customer engagement."
	},
	{
		company_name: "Jerseykart",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/jerseykart.png",
		subtitle: "",
		content: "Our biggest challenge was tracking remittance and profitability through data. Shopdeck’s advanced dashboard gave us real-time insights into our P&L and financial health, which helped us make smarter decisions. The platform has completely transformed the way we operate, streamlining everything from order tracking to financial analysis. Thanks to Shopdeck, we’ve seen a measurable improvement in our profits and operational efficiency."
	},
	{
		company_name: "Gopi Prem",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/gopiprem.png",
		subtitle: "",
		content: "I faced major issues with fraud and claim rejections on marketplaces, which held back my growth. Shopdeck’s platform provided the tools to manage digital marketing campaigns through Facebook and Google Ads effectively. Their insights into RTO rates and marketplace claims processes helped reduce losses and optimize our order management. Today, I’m operating more efficiently and have seen a substantial boost in marketing effectiveness and sales."
	},
	{
		company_name: "Indian Decor Handicrafts",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/rizik.png",
		subtitle: "",
		content: "Transparency was a significant challenge for us across multiple marketplaces. Shopdeck’s campaign management and marketing strategies offered us clarity, helping us track performance and improve customer engagement. With the platform’s powerful analytics, we were able to make data-driven decisions that led to increased visibility and sales. Shopdeck has been instrumental in scaling our business and providing the transparency we needed."
	},
	{
		company_name: "Cotton Fab/Print Art",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/cotton_fab.png",
		subtitle: "",
		content: "Initially, our conversions on marketplaces were low, but Shopdeck’s advanced conversion tools turned things around. Their platform allowed us to track and optimize customer interactions, leading to higher conversion rates. With Shopdeck’s help, we’ve maximized revenue and improved our operational flow, all while enhancing customer satisfaction. The impact on our business has been clear, with sustained growth and higher profitability."
	},
	{
		company_name: "Indian Linen Saree",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/indianlinensaree.png",
		subtitle: "",
		content: "After multiple failed attempts with other platforms, we finally found the solution we needed in Shopdeck. Their website optimization tools and marketing strategies helped us drive traffic and increase order volume. The platform’s analytics provided clear insights into customer behavior, allowing us to adjust and improve our campaigns. The result has been higher conversion rates and strong customer retention, making Shopdeck indispensable to our growth."
	},
	{
		company_name: "The Brand MGSV",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/mgsv.png",
		subtitle: "",
		content: "Before Shopdeck, our focus on D2C (Direct-to-Consumer) was limited. Shopdeck’s powerful tools and guidance helped us build a robust D2C channel. Their comprehensive marketing support and platform integration gave us the push we needed to grow our online sales efficiently. Since working with Shopdeck, we’ve seen a marked improvement in our D2C performance, and I now have a solid strategy to scale further."
	},
	{
		company_name: "Fair Deal Traders",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/asplatinum.png",
		subtitle: "",
		content: "We struggled to find a comprehensive solution that could manage our end-to-end operations, but Shopdeck filled that gap perfectly. Their platform streamlined everything from order management to marketing, with real-time insights into performance. This has improved our fulfilment rates and operational efficiency significantly. Thanks to Shopdeck, our business is more organized and scalable, with clear growth in sales."
	},
	{
		company_name: "Saanvi/Jeweltetoz",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/tree.png",
		subtitle: "",
		content: "High commission costs at marketplaces were a constant burden until we partnered with Shopdeck. Their low-commission model allowed us to keep more profits, while their marketing strategies increased our reach and customer engagement. The platform’s ability to track performance metrics helped us focus on what worked best. Since switching to Shopdeck, we’ve experienced a noticeable uptick in sales and a reduction in operational costs."
	},
	{
		company_name: "Nidhi Fashion",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/ichaa.png",
		subtitle: "",
		content: "Before Shopdeck, we faced issues with managing multiple operations under one roof. Shopdeck’s platform provided a one-stop solution for everything from inventory management to marketing, all while reducing operational complexity. With Shopdeck’s automated processes and real-time reporting, we’ve boosted operational efficiency and seen strong sales growth. Their solution has transformed how we run our business, and we couldn’t be happier with the results."
	},
	{
		company_name: "Binnis Wardrobe",
		url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/testimonial-logo/binnis_wardrobe.png",
		subtitle: "",
		content: "We were bogged down by the technical complexities of running an online store. Managing our website, hosting, and order tracking was time-consuming and inefficient, which limited our ability to grow. Shopdeck’s platform revolutionised how we operate. By automating and streamlining everything—from website maintenance to real-time order and inventory tracking—we were able to shift our focus to scaling the business. The seamless integration of their tech solutions reduced manual errors and gave us valuable insights into our performance."
	},
]
