import React from "react";
import { css } from "@emotion/react";

import WidgetContainer from "atoms/WidgetContainer";
import Text from "atoms/Text";
import Flexbox from "atoms/Flexbox";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";
import { StatsPanelProps } from "./types";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import pricingDetails from "constants/pricingDetails";
import Animated from "molecules/Animated";

const styles = {
	statsPanelContainer: css`
		display: grid;
		width: 100%;
		grid-template-columns: repeat(2, 1fr); /* 4 columns */
    grid-auto-rows: 1fr;
    gap: var(--spacing-md); /* space between items */
    width: 100%;
	`,
	statsPanelContainerXL: css`
		gap: var(--spacing-xxl); /* space between items */
	`,
	statsPanel: css`
		background-color: var(--color-dark-gray);
		border-radius: var(--spacing-md);
		padding: calc(8 * var(--spacing-xs)) var(--spacing-xxl);
		overflow: hidden;
		position: relative;
		width: 100%;
		height: 100%;
	`,
	statsPanelHighlighted: css`
		background-image: var(--color-primary-gradient);
	`
}

const StatsPanel = (props: StatsPanelProps) => {
	const { isHighlighted } = props;
	return (
		<Animated
			animation="zoomIn"
			duration={1}
			iteration={1}
			timingFunction="ease"
			trigger={"auto"}
		>
			<ResponsiveDiv
				css={css`
					${styles.statsPanel};
					${isHighlighted && styles.statsPanelHighlighted};
				`}
			>

				<Flexbox as="article" direction="column" justify="space-between" align="flex-start">
					<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="linear" iteration={1}>
						{isHighlighted ? (
							<Text as="h4" color="white" fontStyleGuide="manrope-heading1" mb="sm">
								{props.label}
							</Text>
						) : (
							<Text as="h4" primaryGradient={true} fontStyleGuide="manrope-heading1" mb="sm">
								{props.label}
							</Text>
						)}
					</Animated>
					<Text color="white" fontStyleGuide="body4">
						{props.sublabel}
					</Text>
				</Flexbox>
			</ResponsiveDiv>
		</Animated>
	)
}

export default () => {
	return (
		<WidgetContainer id="pricing">
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1}>
				<Text align="center" as="h2" color="white" fontStyleGuide="heading4" fontStyleGuideXL="heading4" mb="2xl">
					Pricing
				</Text>
			</Animated>
			<Flexbox
				direction="row"
				justify="space-between"
				align="center"
			>
				<FlexboxItem colspan={24} colspanXL={12} mb="2xl">
					<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1}>
						<Text primaryGradient={true} as="h4" fontStyleGuide="manrope-heading2" align="center" alignXL="left">No upfront cost,</Text>
						<Text color="white" fontStyleGuide="body1" align="center" alignXL="left">only success based fees</Text>
					</Animated>
				</FlexboxItem>
				<FlexboxItem colspan={24} colspanXL={12} mb="2xl">
					<ResponsiveDiv
						css={styles.statsPanelContainer}
						xl={styles.statsPanelContainerXL}
					>
						{pricingDetails.map((pricing, index) => (
							<StatsPanel
								key={`pricing-details-${pricing.sublabel}-${index}`}
								label={pricing.label}
								sublabel={pricing.sublabel}
								isHighlighted={index === 0}
							/>
						))}
					</ResponsiveDiv>
				</FlexboxItem>
			</Flexbox>
		</WidgetContainer>
	)
}
