import React from "react";
import { css } from "@emotion/react";

import Text from "atoms/Text";
import WidgetContainer from "atoms/WidgetContainer";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import Image from "atoms/Image";
import { LeftPanelProps, RightPanelProps, TabGroupProps, TabWithContent } from "./types";
import Flexbox from "atoms/Flexbox";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";
import Animated from "molecules/Animated";
import { isClient } from "utils/helper";
import Arrow from "icons/Arrow";

const tabsWithContent: TabWithContent[] = [
	{
		title: "Website",
		videoUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/offerings-videos/website_final.gif",
		iconUrl: <Image src="https://d2z53scj8veve3.cloudfront.net/shopdeck-home/offerings-logos/website_2.svg" alt="website-offering" width={56} height={56} />,
		collapsedHeight: "calc(348px + 72px)",
		expandedHeight: "calc(480px + 72px)",
		ContentHeader: <Text as="h5" fontStyleGuide="body2" color="white" mb="lg" mt="lg">{`Website - Power Up Your Storefront`}</Text>,
		ContentCollapsed: () => {
			return (
				<ResponsiveDiv>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Endless Customization
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Built for Speed & Growth
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Mobile-Ready
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Flexible Checkout
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Financial Tools
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Customer Support Automation
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Seamless Catalogue Management
					</Text>
				</ResponsiveDiv>
			)
		},
		ContentExpanded: () => {
			return (
				<ResponsiveDiv>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Endless Customization</b> - <span>Multiple customizable themes tailored to your brand</span>
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Built for Speed & Growth</b> - <span>Lightning-fast load times & SEO optimization with unlimited staff/employee accounts</span>
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Mobile-Ready</b> - <span>Drive repeat orders with our free Android app</span>
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Flexible Checkout</b> - <span>Different payment modes (COD, Online and Partial-COD) with modular fields for an optimised checkout experience. Integrations with Razorpay magic, Paytm & Easebuzz</span>
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Financial Tools</b> - <span>Early COD remittance, reconciliation, and automated GST-ready invoice generation</span>
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Customer Support Automation</b> - <span>Chatbot support, automated assistance & order status checks</span>
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Seamless Catalogue Management</b> - <span>Easily create and group product categories</span>
					</Text>
				</ResponsiveDiv>
			)
		}
	},
	{
		title: "Marketing",
		videoUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/offerings-videos/influencer_final.gif",
		iconUrl: <Image src="https://d2z53scj8veve3.cloudfront.net/shopdeck-home/offerings-logos/marketing.svg" alt="marketing-offering" width={56} height={56} />,
		collapsedHeight: "calc(348px + 72px)",
		expandedHeight: "calc(380px + 72px)",
		ContentHeader: <Text as="h5" fontStyleGuide="body2" color="white" mb="lg" mt="lg">{`Marketing - 100% Impact, 0% Commission`}</Text>,
		ContentCollapsed: () => {
			return (
				<ResponsiveDiv>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Effortless Ad Management
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Postpaid Marketing
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; UGC & Influencer Marketing
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; RTO adjusted marketing
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Multi pixel support
					</Text>
				</ResponsiveDiv>
			)
		},
		ContentExpanded: () => {
			return (
				<ResponsiveDiv>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Effortless Ad Management</b> - Integrate with Meta (Fb, Insta) & Google to reach the right customers.
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Postpaid Marketing</b> -  Easier cashflow management with no need to pay in advance
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>UGC & Influencer Marketing</b> - Leverage authentic content and trusted voices to build brand loyalty
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>RTO adjusted marketing</b> - Custom integration with Meta to minimise RTO
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Multi pixel support</b> - Sell different sub categories with custom learnings
					</Text>
				</ResponsiveDiv>
			)
		}
	},
	{
		title: "Conversion",
		videoUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/offerings-videos/conversion_final2.gif",
		iconUrl: <Image src="https://d2z53scj8veve3.cloudfront.net/shopdeck-home/offerings-logos/conversion.svg" alt="conversion-offering" width={56} height={56} />,
		collapsedHeight: "calc(348px + 72px)",
		expandedHeight: "calc(410px + 72px)",
		ContentHeader: <Text as="h5" fontStyleGuide="body2" color="white" mb="lg" mt="lg">{`Conversion Boosting Essentials  - Maximise Conversions`}</Text>,
		ContentCollapsed: () => {
			return (
				<ResponsiveDiv>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Irresistible Offers
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Module for seasonal campaigns (website)
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Optimised Product Experience
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Social Proof & Credibility
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Targeted Promotions & Bundling
					</Text>
				</ResponsiveDiv>
			)
		},
		ContentExpanded: () => {
			return (
				<ResponsiveDiv>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Irresistible Offers</b> - Engage customers with coupons, discounts, and limited-time sales countdowns.
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Module for seasonal campaigns (website)</b> - Engage customers with seasonal banners & promotional activities
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Optimised Product Experience</b>  - Product pages with custom recommendations & upselling widgets aligned to the category
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Social Proof & Credibility</b> - Showcase reviews, testimonials & and trust markers to enhance buyer confidence
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Targeted Promotions & Bundling</b> - Attract and retain customers with targeted promotions and value-added bundles.
					</Text>
				</ResponsiveDiv>
			)
		}
	},
	{
		title: "Whatsapp",
		videoUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/offerings-videos/whatsapp_final2.gif",
		iconUrl: <Image src="https://d2z53scj8veve3.cloudfront.net/shopdeck-home/offerings-logos/whatsapp.svg" alt="whatsapp-offering" width={56} height={56} />,
		collapsedHeight: "calc(348px + 72px)",
		expandedHeight: "calc(538px + 72px)",
		ContentHeader: <Text as="h5" fontStyleGuide="body2" color="white" mb="lg" mt="lg">{`WhatsApp - Optimise Sales & Customer Engagement`}</Text>,
		ContentCollapsed: () => {
			return (
				<ResponsiveDiv>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Recover Lost Sales
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Post check out offers
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Drive Repeat Purchases with Personalization
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Product Launch & Event Alerts
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Modular Real-Time Order Updates
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Loyalty Programs
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Collect Customer Feedback
					</Text>
				</ResponsiveDiv>
			)
		},
		ContentExpanded: () => {
			return (
				<ResponsiveDiv>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Recover Lost Sales</b> - Retargeting brings customers back to complete their purchases
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Post check out offers</b> - Targeting customers with limited time  bumper coupons immediately on purchase
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Drive Repeat Purchases with Personalization</b> - Engage customers with tailored product recommendations based on browsing and purchase history
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Product Launch & Event Alerts</b> - Notify customers about new launches, limited editions, and special sales
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Modular Real-Time Order Updates</b> -  Keep customers informed with live order status
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Loyalty Programs</b> - Creating communities for loyal customers with exclusive deals & early access
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Customer Feedback</b> - Request post-delivery reviews and feedback to gather valuable insights
					</Text>
				</ResponsiveDiv>
			)
		}
	},
	{
		title: "Shipping",
		videoUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/offerings-videos/shipping_final.gif",
		iconUrl: <Image src="https://d2z53scj8veve3.cloudfront.net/shopdeck-home/offerings-logos/shipping.svg" alt="shipping-offering" width={56} height={56} />,
		collapsedHeight: "calc(348px + 72px)",
		expandedHeight: "calc(538px + 72px)",
		ContentHeader: <Text as="h5" fontStyleGuide="body2" color="white" mb="lg" mt="lg">{`Smart Shipping Solutions - Affordable, Fast & Reliable`}</Text>,
		ContentCollapsed: () => {
			return (
				<ResponsiveDiv>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Wide Reach with 20+ Shipping Partners
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Dynamic Courier Allocations
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Postpaid Logistics
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Minimise Returns through a Robust RTO module
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Simplify refunds and exchanges
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Inventory Management
					</Text>
				</ResponsiveDiv>
			)
		},
		ContentExpanded: () => {
			return (
				<ResponsiveDiv>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Wide Reach with 20+ Shipping Partners</b> - Delivering PAN India within a week.
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Dynamic Courier Allocations</b> - Advanced algorithms to ensure speed & reliability of deliveries basis courier partner performance.
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Postpaid Logistics</b> - Pay only after deliveries to enable better cashflow management.
					</Text>
					<Text as="div" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; Minimise Returns through a <b>Robust RTO module</b>
						<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs" ml="sm">
							&#x25E6; Automated NDR handling, last-mile confirmation, step-by-step communication
						</Text>
						<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs" ml="sm">
							&#x25E6; Customer history mgmt, order delay notifications & identification of potential duplicate orders
						</Text>
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Simplify refunds and exchanges</b> - Configure each return step with customizable policies, return windows, modular refunds, media uploads and QC-verified pickups.
					</Text>
					<Text as="p" fontStyleGuide="body4" color="gray-neutral" mb="xs">
						&#x2022; <b>Inventory Management</b> - Real time notifications for low & out-of-stock items, integration with Unicommerce.
					</Text>
				</ResponsiveDiv>
			)
		}
	}
];

const styles = {
	profitabilityEnablerContainerXL: css`
		border-radius: var(--spacing-xxl);
		border: 1px solid var(--color-gray);
		padding: calc(8 * var(--spacing-xs));
		padding-bottom: var(--spacing-sm);
	`,
	tabGroupContainer: css`
		padding: var(--spacing-md);
		background-color: var(--color-dark-gray);
		border-radius: var(--spacing-2xl);
		border: 1px solid var(--color-dark-gray);
		overflow: auto;

		> div:last-child {
			margin-right: 0;
		}
	`,
	tabChip: css`
		border: 1px solid var(--color-gray);
		border-radius: var(--spacing-2xl);
		background-color: transparent;
		color: var(--color-white);
		padding: 10.5px var(--spacing-xl);
		cursor: pointer;
		width: 100%;

		&:hover {
			background-color: var(--color-dark-gray-neutral);
		}
	`,
	tabChipSelected: css`
		border: 1px solid var(--color-white);
		border-radius: var(--spacing-2xl);
		background-color: var(--color-white);
		padding: 10.5px var(--spacing-xl);
		cursor: pointer;
		width: 100%;
	`,
	contentPanel: css`
		border-radius: var(--spacing-xxl);
		padding: var(--spacing-xxl);
		background-color: var(--color-dark-gray);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: min-height .2s linear, height .2s linear;
	`,
	contentPanelExpanded: css`
	`,
	viewExpander: css`
		cursor: pointer;
		z-index: 1;
	`

}

//		height: 320px; // needed for animation
// 		height: calc(490px + 48px);


const TabGroup = React.memo((props: TabGroupProps): JSX.Element => {
	const { selectedTab, onTabChange } = props;

	const tabs = tabsWithContent.map(t => t.title);

	return (
		<Flexbox
			direction="row"
			wrap="nowrap"
			justify="space-between"
			align="center"
			css={styles.tabGroupContainer}
			mb="xxl"
		>
			{tabs.map(tab => (
				<FlexboxItem
					as="div"
					colspanXL={4}
					colspan={7}
					mr="xxl"
					key={`offerings-tab-${tab}`}
				>
					<ResponsiveDiv
						onClick={() => onTabChange(tab)}
						css={css`
							${tab === selectedTab ? styles.tabChipSelected : styles.tabChip}
						`}
					>
						<Text as="p" align="center" fontStyleGuide="body5" color={tab === selectedTab ? "black" : "white"}>
							{tab}
						</Text>
					</ResponsiveDiv>
				</FlexboxItem>
			))}
		</Flexbox>
	)
});

const LeftPanel = React.memo((props: LeftPanelProps): JSX.Element => {
	const { isExpanded, selectedTab, propertyToAnimate } = props;
	return (
		<FlexboxItem
			colspan={24}
			colspanXL={11.7}
			mb="xxl"
		>
			<ResponsiveDiv
				css={css`
					${styles.contentPanel};
					${isExpanded && styles.contentPanelExpanded};
					background-image: url('${props.selectedTab.videoUrl}');
					background-repeat: no-repeat;
					background-size: cover;
					min-height: 420px;
				`}
				xl={css`
					${`${propertyToAnimate}: ${isExpanded ? selectedTab.expandedHeight : selectedTab.collapsedHeight};`}
				`} />
			{/* <Image src={props.selectedTab.videoUrl} loading="eager" /> */}
		</FlexboxItem>
	)
});

const RightPanel = React.memo((props: RightPanelProps): JSX.Element => {
	const { isExpanded, selectedTab, propertyToAnimate, setExpanded } = props;

	return (
		<FlexboxItem
			colspan={24}
			colspanXL={11.7}
			mb="xxl"
			css={css`
				${styles.contentPanel};
				${isExpanded && styles.contentPanelExpanded};
				${`${propertyToAnimate}: ${isExpanded ? selectedTab.expandedHeight : selectedTab.collapsedHeight};`}
			`}
		>
			<ResponsiveDiv
				css={css`
					position: relative;
				`}
			>
				{selectedTab.iconUrl}
				{selectedTab.ContentHeader}
				<ResponsiveDiv
					css={css`
						display: grid;
						grid-template-columns: 1fr;
					`}
				>
					<ResponsiveDiv
						css={css`
							grid-row-start: 1;
							grid-column-start: 1;
							opacity: 1;
							transition: all .2s linear;
							${isExpanded ? `opacity: 0;` : ''}
						`}
					>
						<selectedTab.ContentCollapsed />
					</ResponsiveDiv>
					<ResponsiveDiv
						css={css`
							grid-row-start: 1;
							grid-column-start: 1;
							opacity: 0;
							transition: all .2s linear;
							height: 0;

							${isExpanded ? `opacity: 1;height: unset;` : ''}
						`}
					>
						<selectedTab.ContentExpanded />
					</ResponsiveDiv>
				</ResponsiveDiv>
			</ResponsiveDiv>
			<ResponsiveDiv css={styles.viewExpander} as="span" onClick={() => setExpanded(!isExpanded)}>
				<Flexbox direction="row" align="center" justify="flex-start">
					<Text
						as="p"
						color="primary"
						fontStyleGuide="body4"
					>
						{isExpanded ? `View Less` : `View Detail`}&nbsp;
					</Text>
					<Arrow direction={isExpanded ? 'up' : 'down'} />
				</Flexbox>
			</ResponsiveDiv>

		</FlexboxItem>
	);
});


export default () => {
	const [selectedTab, setSelectedTab] = React.useState(tabsWithContent[0]);
	const [isExpanded, setExpanded] = React.useState(false);

	const updateSelectedTab = React.useCallback((tab: string) => {
		setSelectedTab(tabsWithContent.find(t => t.title === tab) || tabsWithContent[0])
		setExpanded(false);
	}, []);

	const propertyToAnimate = isClient ? (window.innerWidth < 1200 ? 'min-height' : 'height') : 'height';

	return (
		<WidgetContainer id="our-offerings">
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1}>
				<Text as="h4" primaryGradient={true} align="center" fontStyleGuide="body2" mb="sm">{"Our Offerings"}</Text>
			</Animated>
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.1}>
				<Text as="h2" color="white" align="center" fontStyleGuide="heading3">{"Your Profitability Enabler"}</Text>
			</Animated>
			<ResponsiveDiv
				xl={styles.profitabilityEnablerContainerXL}
				mt="2xl"
			>
				<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.2}>
					<TabGroup selectedTab={selectedTab.title} onTabChange={updateSelectedTab} />
				</Animated>
				<Flexbox
					direction="row"
					mt="sm"
					justify="space-between"
					align="stretch"
				>
					<LeftPanel key={`left-panel-${selectedTab.title}`} propertyToAnimate={propertyToAnimate} selectedTab={selectedTab} isExpanded={isExpanded} />
					<RightPanel key={`right-panel-${selectedTab.title}`} propertyToAnimate={propertyToAnimate} selectedTab={selectedTab} isExpanded={isExpanded} setExpanded={setExpanded} />
				</Flexbox>
			</ResponsiveDiv>
		</WidgetContainer>
	)
}
