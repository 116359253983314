import React from "react";
import { css } from "@emotion/react";
import Divider from "atoms/Divider";
import Header from "organisms/Header";
import RunYourD2CBusiness from "organisms/HomePageWidgets/RunYourD2CBusiness";
import TrustedByBrands from "organisms/HomePageWidgets/TrustedByBrands";
import WeHelpYouSucceed from "organisms/HomePageWidgets/WeHelpYouSucceed";
import OurOfferings from "organisms/HomePageWidgets/OurOfferings";
import OurMission from "organisms/HomePageWidgets/OurMission";
import OurPhilosophy from "organisms/HomePageWidgets/OurPhilosophy";
import Founders from "organisms/HomePageWidgets/Founders";
import GrowthConsulting from "organisms/HomePageWidgets/GrowthConsulting";
import Pricing from "organisms/HomePageWidgets/Pricing";
import Testimonial from "organisms/HomePageWidgets/Testimonial";
import Footer from "organisms/Footer";
import ContactUs from "organisms/HomePageWidgets/ContactUs";
import YourJourney from "organisms/HomePageWidgets/YourJourney";
import OurImpact from "organisms/HomePageWidgets/OurImpact";
import BackedByBest from "organisms/HomePageWidgets/BackedByBest";

export default () => {
	return (
		<>
			<Header />
			<RunYourD2CBusiness />
			<TrustedByBrands />
			<WeHelpYouSucceed />
			<OurOfferings />
			<GrowthConsulting />
			<Testimonial />
			<Pricing />
			<YourJourney />
			<Founders />
			<OurMission />
			<OurPhilosophy />
			<OurImpact />
			<BackedByBest />
			<ContactUs />
			<Footer />
		</>
	)
}
