export default {
	rishabh: {
		name: "Rishabh Verma",
		imageUrl: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-200,f-webp,fo-auto/shopdeck-home/founder-rishabh-verma-shopdeck-200.jpg",
		//imageUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/founder-rishabh-verma-shopdeck-200.jpg",
		linkedInUrl: "https://www.linkedin.com/in/rishabhv07/",
	},
	harmin: {
		name: "Harmin Shah",
		//imageUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/founder-harmin-shah-shopdeck-200.jpg",
		imageUrl: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-200,f-webp,fo-auto/shopdeck-home/founder-harmin-shah-shopdeck-200.jpg",
		linkedInUrl: "https://www.linkedin.com/in/harminshah/",
	}
}
