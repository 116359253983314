import { ImageProps } from "./types";
import React from "react";

const Image = React.forwardRef((props: ImageProps, ref): JSX.Element => {
  const { src, alt, ...rest } = props;

	// TODO: Add logic for lazy loading of images in background
  return <img ref={ref as any} src={src} alt={alt} {...rest} />;
});

export default Image;
