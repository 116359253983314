export default [
	{
		label: "₹0",
		sublabel: "Subscription Fee"
	},
	{
		label: "₹0",
		sublabel: "Website Setup & Maintenance Cost"
	},
	{
		label: "0%",
		sublabel: "Commission on Marketing Budget"
	},
	{
		label: "3%",
		sublabel: "+ GST, on Delivered Orders"
	}
]
