import { css } from "@emotion/react";
import WidgetContainer from "atoms/WidgetContainer";
import Text from "atoms/Text";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import Image from "atoms/Image";

import Animated from "molecules/Animated";
import Flexbox from "atoms/Flexbox";
import React from "react";

export default () => {
	return (
		<WidgetContainer id="our-mission">
			<Animated animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} trigger="inview">
				<Text fontStyleGuide="heading4" color="white" align="center">
					Our &nbsp;
					<Text as="span" fontStyleGuide="heading4" primaryGradient={true}>Mission</Text>
				</Text>
			</Animated>
			<Animated animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} trigger="inview" delay={0.1}>
				<Text mt="2xl" mb="2xl" fontStyleGuide="heading3" fontStyleGuideXL="manrope-heading1" color="white" align="center">
					Democratise D2C Online Commerce
				</Text>
			</Animated>

			<Animated animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} trigger="inview" delay={0.2}>
				<ResponsiveDiv
					as="div"
					css={css`
					max-width: 768px;
					max-height: 406px;
					margin: 0 auto;

					img {
						width: 100%;
						height: auto;
					}
				`}
				>
					<Image
						width={1024}
						height={1024}
						alt="shopdeck-our-mission"
						src="https://d2z53scj8veve3.cloudfront.net/shopdeck-home/our-mission-illustration-1024.png"
						loading="lazy"
					/>
				</ResponsiveDiv>
			</Animated>

		</WidgetContainer>
	)
}
