import React from "react";
import { css } from "@emotion/react";
import Link from "atoms/Link";
import { ShopdeckLogoProps } from "./types";


export default (props: ShopdeckLogoProps) => {
	const { width = 203, height = 48, ...rest} = props;
	return (
		<Link to="/#run-your-d2c-brand" onClick={() => window.scrollTo(0,0)}>
			{/* <img
				css={css`
					display: inline;
    			vertical-align: middle;
					object-fit: contain;
				`}
				alt="ShopDeck-logo"
				src="https://cdn.zeplin.io/6433a23cf1f1aa25d8e6725e/assets/a7ca3d39-815f-4007-a229-52762925ccbd.svg"
				{...props}
			/> */}
			<svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M0 5C0 4.44772 0.447715 4 1 4H27.0097C27.5619 4 28.0097 4.44772 28.0097 5V8.99999H8.99988C6.79074 8.99999 4.99988 10.7908 4.99988 13V32.0097H1C0.447716 32.0097 0 31.5619 0 31.0097V5ZM11.9903 39.0001V43C11.9903 43.5523 12.438 44 12.9903 44H39C39.5523 44 40 43.5523 40 43V16.9903C40 16.4381 39.5523 15.9903 39 15.9903H35V35.0001C35 37.2092 33.2091 39.0001 31 39.0001H11.9903ZM33.1337 15.9903V35.0001C33.1337 36.1785 32.1784 37.1338 31 37.1338H11.9903V32.0097H27.0097C27.5619 32.0097 28.0097 31.5619 28.0097 31.0097V15.9903H33.1337ZM28.0097 10.8663V15.9903H12.9903C12.438 15.9903 11.9903 16.4381 11.9903 16.9903V32.0097H6.86619V13C6.86619 11.8216 7.82148 10.8663 8.99988 10.8663H28.0097Z" fill="url(#paint0_linear_293_17395)" />
				<path d="M57.056 36.5582C55.0664 36.5582 53.3513 36.2374 51.9106 35.5958C50.6545 35.0071 49.4882 34.1577 48.4116 33.0475C48.176 32.8045 48.1884 32.4185 48.4287 32.1801L51.017 29.6121C51.2779 29.3532 51.7019 29.3705 51.9603 29.6319C52.6121 30.2914 53.3159 30.8243 54.0717 31.2306C55.0321 31.7118 56.1413 31.9524 57.3991 31.9524C58.5425 31.9524 59.4229 31.7462 60.0404 31.3337C60.6807 30.9213 61.0009 30.3484 61.0009 29.6151C61.0009 28.9506 60.7951 28.4121 60.3834 27.9997C59.9718 27.5872 59.4229 27.232 58.7369 26.9342C58.0737 26.6363 57.3305 26.3498 56.5072 26.0749C55.7068 25.7999 54.9064 25.4905 54.106 25.1468C53.3056 24.7802 52.5623 24.3334 51.8763 23.8063C51.2131 23.2564 50.6756 22.5804 50.264 21.7784C49.8524 20.9535 49.6466 19.9223 49.6466 18.685C49.6466 17.1955 50.001 15.9123 50.7099 14.8353C51.4417 13.7584 52.448 12.9449 53.7286 12.395C55.0093 11.8221 56.4843 11.5357 58.1537 11.5357C59.846 11.5357 61.3897 11.8336 62.7846 12.4293C63.9966 12.9386 65.0247 13.6069 65.8691 14.4342C66.1101 14.6704 66.0962 15.0556 65.8567 15.2933L63.2417 17.8878C62.9902 18.1373 62.5848 18.1312 62.3235 17.8919C61.7411 17.3585 61.1516 16.9469 60.5549 16.657C59.8231 16.2904 58.9884 16.1071 58.0508 16.1071C57.1132 16.1071 56.37 16.2904 55.8211 16.657C55.2723 17.0008 54.9978 17.5049 54.9978 18.1694C54.9978 18.7881 55.2037 19.2922 55.6153 19.6817C56.0269 20.0484 56.5643 20.3692 57.2275 20.6441C57.9136 20.9191 58.6568 21.1941 59.4572 21.4691C60.2805 21.744 61.0924 22.0648 61.8928 22.4315C62.6932 22.7981 63.425 23.2679 64.0882 23.8407C64.7742 24.3907 65.3231 25.101 65.7347 25.9718C66.1464 26.8196 66.3522 27.8736 66.3522 29.1339C66.3522 31.4483 65.5289 33.27 63.8823 34.599C62.2358 35.9052 59.9604 36.5582 57.056 36.5582Z" fill="white" />
				<path d="M80.2636 36.1801C79.9124 36.1801 79.6278 35.8954 79.6278 35.5442V26.6592C79.6278 25.7884 79.3533 25.0895 78.8045 24.5625C78.2785 24.0126 77.6039 23.7376 76.7806 23.7376C76.2089 23.7376 75.7058 23.8636 75.2713 24.1157C74.8368 24.3448 74.4937 24.6885 74.2422 25.1468C74.15 25.3063 74.0748 25.4749 74.0164 25.6528C73.8695 26.1004 73.3706 26.4158 72.948 26.2076L72.1959 25.8372C71.9786 25.7302 71.8389 25.5088 71.8495 25.2668C71.8999 24.1237 72.1715 23.1098 72.6642 22.2252C73.2131 21.2399 73.9792 20.4837 74.9625 19.9567C75.9459 19.4068 77.0779 19.1318 78.3585 19.1318C79.6621 19.1318 80.8055 19.4068 81.7888 19.9567C82.7722 20.4837 83.5269 21.2285 84.0529 22.1909C84.6017 23.1304 84.8761 24.2303 84.8761 25.4905V35.5442C84.8761 35.8954 84.5914 36.1801 84.2402 36.1801H80.2636ZM69.2523 36.1801C68.9011 36.1801 68.6164 35.8954 68.6164 35.5442V11.8622C68.6164 11.511 68.9011 11.2263 69.2523 11.2263H73.2289C73.5801 11.2263 73.8648 11.511 73.8648 11.8622V35.5442C73.8648 35.8954 73.5801 36.1801 73.2289 36.1801H69.2523Z" fill="white" />
				<path d="M95.5878 36.5582C93.8727 36.5582 92.3176 36.1801 90.9226 35.424C89.5505 34.6449 88.4642 33.5908 87.6638 32.2618C86.8634 30.9327 86.4632 29.4433 86.4632 27.7934C86.4632 26.1436 86.8634 24.6656 87.6638 23.3595C88.4642 22.0534 89.5505 21.0222 90.9226 20.2661C92.2947 19.487 93.8498 19.0974 95.5878 19.0974C97.3258 19.0974 98.8809 19.4755 100.253 20.2317C101.625 20.9879 102.711 22.0305 103.512 23.3595C104.312 24.6656 104.712 26.1436 104.712 27.7934C104.712 29.4433 104.312 30.9327 103.512 32.2618C102.711 33.5908 101.625 34.6449 100.253 35.424C98.8809 36.1801 97.3258 36.5582 95.5878 36.5582ZM95.5878 31.7806C96.3425 31.7806 97.0057 31.6202 97.5774 31.2994C98.1491 30.9556 98.5836 30.4859 98.8809 29.8901C99.2011 29.2714 99.3612 28.5725 99.3612 27.7934C99.3612 27.0144 99.2011 26.3384 98.8809 25.7655C98.5608 25.1697 98.1148 24.7115 97.5431 24.3907C96.9942 24.0469 96.3425 23.8751 95.5878 23.8751C94.856 23.8751 94.2043 24.0469 93.6325 24.3907C93.0608 24.7115 92.6149 25.1697 92.2947 25.7655C91.9746 26.3613 91.8145 27.0487 91.8145 27.8278C91.8145 28.584 91.9746 29.2714 92.2947 29.8901C92.6149 30.4859 93.0608 30.9556 93.6325 31.2994C94.2043 31.6202 94.856 31.7806 95.5878 31.7806Z" fill="white" />
				<path d="M116.244 36.5238C115.124 36.5238 114.095 36.3062 113.157 35.8708C112.219 35.4354 111.453 34.8396 110.859 34.0835C110.264 33.3044 109.921 32.4222 109.83 31.4368V24.3907C109.921 23.4053 110.264 22.5117 110.859 21.7097C111.453 20.9077 112.219 20.2775 113.157 19.8192C114.095 19.3609 115.124 19.1318 116.244 19.1318C117.822 19.1318 119.217 19.5099 120.429 20.2661C121.664 21.0222 122.625 22.0534 123.311 23.3595C124.02 24.6656 124.374 26.1551 124.374 27.8278C124.374 29.5006 124.02 30.99 123.311 32.2961C122.625 33.6023 121.664 34.6334 120.429 35.3896C119.217 36.1458 117.822 36.5238 116.244 36.5238ZM115.284 31.7806C116.038 31.7806 116.69 31.6087 117.239 31.265C117.811 30.9213 118.257 30.4515 118.577 29.8557C118.897 29.26 119.057 28.584 119.057 27.8278C119.057 27.0487 118.897 26.3613 118.577 25.7655C118.257 25.1697 117.811 24.7115 117.239 24.3907C116.69 24.0469 116.05 23.8751 115.318 23.8751C114.586 23.8751 113.934 24.0469 113.363 24.3907C112.814 24.7115 112.379 25.1697 112.059 25.7655C111.739 26.3613 111.579 27.0487 111.579 27.8278C111.579 28.584 111.728 29.26 112.025 29.8557C112.345 30.4515 112.791 30.9213 113.363 31.265C113.934 31.6087 114.575 31.7806 115.284 31.7806ZM107.275 43.2263C106.924 43.2263 106.639 42.9416 106.639 42.5904V20.1114C106.639 19.7602 106.924 19.4755 107.275 19.4755H111.252C111.603 19.4755 111.888 19.7602 111.888 20.1114V23.7055C111.888 23.7497 111.883 23.7938 111.874 23.837L111.057 27.7015C111.039 27.7849 111.039 27.8709 111.055 27.9545L111.807 31.8235C111.815 31.8635 111.819 31.9042 111.819 31.945V42.5904C111.819 42.9416 111.534 43.2263 111.183 43.2263H107.275Z" fill="white" />
				<path d="M131.107 36.1801C130.755 36.1801 130.471 35.8954 130.471 35.5442V32.0727C130.471 31.7215 130.755 31.4368 131.107 31.4368H136.474C137.937 31.4368 139.218 31.1504 140.316 30.5776C141.413 29.9818 142.259 29.1225 142.854 27.9997C143.449 26.8769 143.746 25.5478 143.746 24.0126C143.746 22.4773 143.437 21.1597 142.82 20.0598C142.225 18.9599 141.379 18.1121 140.281 17.5163C139.206 16.9206 137.937 16.6227 136.474 16.6227H135.394C135.043 16.6227 134.759 16.338 134.759 15.9868V12.5496C134.759 12.1984 135.043 11.9138 135.394 11.9138H136.542C138.372 11.9138 140.053 12.2116 141.585 12.8074C143.14 13.3803 144.489 14.2167 145.633 15.3165C146.799 16.3935 147.691 17.6767 148.308 19.1662C148.949 20.6327 149.269 22.2596 149.269 24.0469C149.269 25.8114 148.949 27.4383 148.308 28.9277C147.691 30.4172 146.81 31.7004 145.667 32.7773C144.523 33.8543 143.174 34.6907 141.619 35.2865C140.087 35.8822 138.418 36.1801 136.611 36.1801H131.107ZM127.47 36.1801C127.119 36.1801 126.834 35.8954 126.834 35.5442V12.5496C126.834 12.1984 127.119 11.9138 127.47 11.9138H131.584C131.935 11.9138 132.22 12.1984 132.22 12.5496V35.5442C132.22 35.8954 131.935 36.1801 131.584 36.1801H127.47Z" fill="white" />
				<path d="M159.762 36.5582C157.956 36.5582 156.343 36.1916 154.926 35.4583C153.531 34.7022 152.433 33.6595 151.632 32.3305C150.832 31.0015 150.432 29.5006 150.432 27.8278C150.432 26.1551 150.821 24.6656 151.598 23.3595C152.399 22.0305 153.473 20.9879 154.823 20.2317C156.172 19.4755 157.693 19.0974 159.385 19.0974C161.031 19.0974 162.484 19.4526 163.741 20.1629C164.999 20.8733 165.983 21.8586 166.692 23.1189C167.423 24.3792 167.789 25.8228 167.789 27.4497C167.789 27.7476 167.766 28.0684 167.721 28.4121C167.707 28.6016 167.682 28.811 167.644 29.0404C167.596 29.3367 167.334 29.5469 167.034 29.5476L153.813 29.5793C153.462 29.5801 153.176 29.2952 153.176 28.9434V26.6058C153.176 26.2554 153.46 25.9709 153.81 25.97L163.561 25.9424C164.087 25.9409 164.294 26.6221 163.857 26.9139C163.51 27.1463 163.039 26.9028 162.983 26.4882C162.911 25.9468 162.786 25.4768 162.609 25.0781C162.335 24.4365 161.923 23.9553 161.375 23.6345C160.849 23.2908 160.197 23.1189 159.419 23.1189C158.596 23.1189 157.876 23.3137 157.258 23.7032C156.664 24.0699 156.195 24.5969 155.852 25.2843C155.532 25.9718 155.371 26.8081 155.371 27.7934C155.371 28.7788 155.543 29.6266 155.886 30.337C156.252 31.0244 156.755 31.5629 157.395 31.9524C158.059 32.319 158.836 32.5024 159.728 32.5024C160.551 32.5024 161.294 32.3649 161.958 32.0899C162.42 31.882 162.844 31.6073 163.229 31.2655C163.504 31.0214 163.927 31.0043 164.187 31.2645L166.173 33.255C166.408 33.4899 166.424 33.8676 166.191 34.1036C165.451 34.8526 164.589 35.4302 163.604 35.8364C162.438 36.3176 161.157 36.5582 159.762 36.5582Z" fill="white" />
				<path d="M177.698 36.5582C175.983 36.5582 174.427 36.1801 173.033 35.424C171.638 34.6678 170.54 33.6252 169.739 32.2961C168.939 30.9671 168.539 29.4777 168.539 27.8278C168.539 26.1551 168.939 24.6656 169.739 23.3595C170.563 22.0305 171.672 20.9879 173.067 20.2317C174.462 19.4755 176.028 19.0974 177.766 19.0974C179.07 19.0974 180.259 19.3266 181.334 19.7849C182.246 20.1466 183.071 20.6665 183.809 21.3446C184.063 21.5774 184.056 21.9723 183.813 22.2159L181.364 24.6701C181.105 24.9288 180.686 24.9112 180.398 24.6864C180.129 24.4769 179.835 24.3096 179.516 24.1844C179.013 23.9782 178.43 23.8751 177.766 23.8751C177.012 23.8751 176.337 24.0469 175.742 24.3907C175.171 24.7115 174.713 25.1697 174.37 25.7655C174.05 26.3384 173.89 27.0144 173.89 27.7934C173.89 28.5725 174.05 29.26 174.37 29.8557C174.713 30.4515 175.182 30.9213 175.777 31.265C176.371 31.6087 177.035 31.7806 177.766 31.7806C178.452 31.7806 179.058 31.666 179.584 31.4368C179.92 31.2828 180.225 31.0945 180.501 30.872C180.786 30.6415 181.209 30.6263 181.467 30.8873L183.886 33.3363C184.127 33.5804 184.132 33.9736 183.881 34.2075C183.124 34.9141 182.286 35.4685 181.368 35.8708C180.27 36.3291 179.047 36.5582 177.698 36.5582Z" fill="white" />
				<path d="M196.975 36.1801C196.763 36.1801 196.565 36.0749 196.447 35.8994L191.052 27.886C190.903 27.6651 190.908 27.375 191.064 27.1591L196.412 19.7396C196.531 19.5738 196.723 19.4755 196.927 19.4755H201.171C201.7 19.4755 201.998 20.0827 201.674 20.5006L196.206 27.5639C195.988 27.8459 195.537 27.6714 195.565 27.316C195.591 26.984 196.016 26.8638 196.213 27.1329L202.074 35.1696C202.38 35.5898 202.08 36.1801 201.56 36.1801H196.975ZM186.604 36.1801C186.253 36.1801 185.968 35.8954 185.968 35.5442V11.8622C185.968 11.511 186.253 11.2263 186.604 11.2263H190.58C190.932 11.2263 191.216 11.511 191.216 11.8622V35.5442C191.216 35.8954 190.932 36.1801 190.58 36.1801H186.604Z" fill="white" />
				<defs>
					<linearGradient id="paint0_linear_293_17395" x1="0" y1="44" x2="40" y2="44" gradientUnits="userSpaceOnUse">
						<stop offset="0.614608" stopColor="#4764CD" />
						<stop offset="1" stopColor="#5694F2" />
					</linearGradient>
				</defs>
			</svg>

		</Link>
	)
}
