import React from "react";
import { css } from "@emotion/react";

import Text from "atoms/Text";
import Image from "atoms/Image";
import Flexbox from "atoms/Flexbox";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import WidgetContainer from "atoms/WidgetContainer";
import Animated from "molecules/Animated";
import { TestimonialItemProps } from "./types";

import testimonialDetails from "constants/testimonialDetails";

const styles = {
	testimonialScrollerWrapper: css`
		overflow: hidden;
		position: relative;

		&:hover div[data-animation] {
			animation-play-state: paused;
		}
	`,
	testimonialCardWrapper: css`
		display: grid;
		width: 100%;
		grid-auto-columns: 1fr;
		grid-template-columns: repeat(11, 414px);
		grid-template-rows: auto;
		//grid-column-gap: var(--spacing-xxl);
		//grid-row-gap: var(--spacing-xxl);
	`,
	testimonialCardContainer: css`
		margin: 0 var(--spacing-md);
	`,
	testimonialCard: css`
		background-color: var(--color-primary-015);
		border-radius: var(--spacing-xl);
		padding: var(--spacing-xxl);
		width: 100%;
		height: 100%;
	`,
	testimonialImageWrapper: css`
		width: 100%;
		text-align: center;
		height: 64px;
	`,
	testimonialImage: css`
		width: 100%;
		height: 100%;
		max-height: 64px;
		object-fit: contain;
	`,
	leftMask: css`
		display: none;
		position: absolute;
		background-image: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0.1) 81%);
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 70px;
	`,
	rightMask: css`
		display: none;
		position: absolute;
		background-image: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0.1) 81%);
		top: 0;
		bottom: 0;
		right: -1px;
		z-index: 1;
		width: 70px;
		transform: rotate(180deg);
	`,
	leftMaskXL: css`
		display: block;
	`,
	rightMaskXL: css`
		display: block;
	`
}

const TestimonialItem = (props: TestimonialItemProps): JSX.Element => {
	return (
		<ResponsiveDiv
			css={styles.testimonialCardContainer}
		>
			<ResponsiveDiv
				css={styles.testimonialCard}
			>
				<ResponsiveDiv
					mb="lg"
					css={styles.testimonialImageWrapper}
				>
					<Image loading="lazy" css={styles.testimonialImage} src={props.url} alt={`testimonial-${props.companyName}`} />
				</ResponsiveDiv>
				<Text
					color="gray-neutral"
					fontStyleGuide="body5"
					mb="sm"
				>
					{props.companyName}
				</Text>
				<Text
					color="white"
					fontStyleGuide="body4"
				>
					“{props.content}”
				</Text>
			</ResponsiveDiv>
		</ResponsiveDiv>
	)
}

export default () => {
	return (
		<WidgetContainer id="testimonials">
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1}>
				<Text align="center" as="h2" color="white" fontStyleGuide="heading4" fontStyleGuideXL="heading4" mb="2xl">
					Testimonials
				</Text>
			</Animated>
			<ResponsiveDiv css={styles.testimonialScrollerWrapper}>
				<ResponsiveDiv as="span" xl={styles.leftMaskXL} css={styles.leftMask} />
				<Flexbox direction="row" justify="space-between" wrap="nowrap" align="center">
					<Animated animationOnHover="pause" trigger="auto" animation="slideOutLeft" duration={90} iteration={"infinite"} timingFunction="linear">
						<ResponsiveDiv css={styles.testimonialCardWrapper}>
							{testimonialDetails.map(testimonial => (
								<TestimonialItem key={`row1-${testimonial.company_name}`} url={testimonial.url} companyName={testimonial.company_name} content={testimonial.content} />
							))}
						</ResponsiveDiv>
					</Animated>
					<Animated animationOnHover="pause" trigger="auto" animation="slideOutLeft" duration={90} iteration={"infinite"} timingFunction="linear">
						<ResponsiveDiv css={styles.testimonialCardWrapper}>
							{testimonialDetails.map(testimonial => (
								<TestimonialItem key={`row2-${testimonial.company_name}`} url={testimonial.url} companyName={testimonial.company_name} content={testimonial.content} />
							))}
						</ResponsiveDiv>
					</Animated>
				</Flexbox>
				<ResponsiveDiv as="span" css={styles.rightMask} xl={styles.rightMaskXL} />
			</ResponsiveDiv>
		</WidgetContainer>
	)
}
