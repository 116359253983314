import { IconProps } from "./types";

export default (props: IconProps): JSX.Element => {
	const { width = 24, height = 24 } = props;
	return (
		<svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Socials">
				<path id="bg" d="M0 1.71921C0 0.769972 0.794024 0 1.77355 0H22.2264C23.2059 0 24 0.769972 24 1.71921V22.2808C24 23.2303 23.2059 24 22.2264 24H1.77355C0.794024 24 0 23.2303 0 22.2808V1.71921Z" fill="#006699" />
				<g id="in">
					<path fillRule="evenodd" clipRule="evenodd" d="M7.57679 20.0905V9.25314H3.97466V20.0905H7.57679ZM5.7757 7.77349C7.03184 7.77349 7.81368 6.9413 7.81368 5.90131C7.79027 4.8379 7.03184 4.02881 5.79954 4.02881C4.56735 4.02881 3.76172 4.8379 3.76172 5.90131C3.76172 6.9413 4.54339 7.77349 5.75223 7.77349H5.7757Z" fill="white" />
					<path fillRule="evenodd" clipRule="evenodd" d="M9.56996 20.0905H13.1721V14.0385C13.1721 13.7146 13.1955 13.391 13.2906 13.1594C13.551 12.5123 14.1437 11.8421 15.1388 11.8421C16.4422 11.8421 16.9636 12.8359 16.9636 14.2928V20.0905H20.5654V13.8765C20.5654 10.5478 18.7883 8.99884 16.4184 8.99884C14.4752 8.99884 13.6221 10.085 13.1481 10.8248H13.1722V9.25314H9.57006C9.61733 10.27 9.56996 20.0905 9.56996 20.0905Z" fill="white" />
				</g>
			</g>
		</svg>
	)
}
