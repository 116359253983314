import React, { PropsWithChildren } from "react";
import { css } from "@emotion/react";
import { ButtonProps } from "./types";
import { useNavigate } from "react-router-dom";

const styles = {
	normalize: css`
		position: relative;
		appearance: none;
		overflow: hidden;
		outline: 0px;
		&:hover, &:active, &:focus {
			outline: 0px;
		}
		border-width: 1px;
		border-style: solid;
		cursor: pointer;
	`,
	base: css`
		font-family: var(--font-family-body5);
		font-size: var(--font-size-body5);
		font-weight: var(--font-weight-body5);
		line-height: var(--font-lineheight-body5);
		padding: 10.5px var(--spacing-xl);
		border-radius: 40px;
	`,
	primaryType: css`
		background-color: var(--color-white);
		background-image: linear-gradient(90deg, white, white);
		color: var(--color-black);
		transition: background-image .3s;

		&:hover {
			background-image: var(--color-primary-gradient);
			color: var(--color-white);
		}

		&:hover * {
			fill: var(--color-white);
		}
	`,
	secondaryType: css`
		background-color: var(--color-black);
		border-color: var(--color-gray);
		color: var(--color-white);

		&:hover {
			background-color: var(--color-dark-gray-neutral);
		}
	`,
	disabled: css`
		background-image: unset;
    background-color: rgba(255, 255, 255, .4);
    color: white;
		cursor: not-allowed !important;
		&:hover {
			background-image: unset;
    	background-color: rgba(255, 255, 255, .4);
		}
	`,
	fullWidth: css`
		width: 100%;
	`
}

const noOp = () => {}

const Button = (props: PropsWithChildren<ButtonProps>): JSX.Element => {
	const { type, full = false, to, navigateOptions, onClick, disabled } = props;
	const navigate = useNavigate();

	const onClickHandler = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		to && navigate(to, navigateOptions);
		onClick && onClick(event);
	}, [onClick, navigate, to, navigateOptions]);

	return (
		<button
			css={css`
				${styles.normalize};
				${styles.base};
				${full && styles.fullWidth};
				${styles[`${type}Type`]};
				${disabled && styles.disabled};
			`}
			onClick={disabled ? noOp : onClickHandler}
			disabled={disabled}
		>
			{props.children}
		</button>
	)
}

export default Button;
