export default [
	{
		title: "$150M",
		subtitle: "in annual sales across sellers"
	},
	{
		title: "500+",
		subtitle: "sellers are doing ₹ 1 Crore+ sales annually"
	},
	{
		title: "~60%",
		subtitle: "of our sellers have had a YoY GMV growth >= 100%"
	},
	{
		title: ">2M",
		subtitle: "customers visit our sellers on a daily basis"
	},
	{
		title: ">90%",
		subtitle: "of our sellers are selling D2C for the first time"
	},
	{
		title: ">75%",
		subtitle: "of our sellers are from tier-2 & tier-3 cities"
	},
	{
		title: ">80%",
		subtitle: "of our buyers are from tier-2 & tier-3 cities "
	},
]
