// export default [
// 	{ company_name: "binniswardrobe", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/binnis_wardrobe.svg",},
// 	{ company_name: "cottonfab", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/cotton_fab.svg"},
// 	{ company_name: "fashionjewel", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/fashion_jewel.svg"},
// 	{ company_name: "icha", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/icha.svg"},
// 	{ company_name: "asplatinum", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/asplatinum.svg"},
// 	{ company_name: "tree_company", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/tree_company.svg"},
// 	{ company_name: "fargo", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/fargo.svg"},
// 	{ company_name: "rizik", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/rizik.svg"},
// 	{ company_name: "jerseykart", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/jerseykart.svg"},
// 	{ company_name: "mgsv", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/mgsv.svg"},
// 	//{ company_name: "indianlinensaree", url: "https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-600,f-webp,fo-auto/shopdeck-home/indianlinensaree.svg"},
// ]

// dont use napo url -> its cloudfront with imagekit with s3
// use veve3 url -> its cloudfront with s3
export default [
	{ company_name: "binniswardrobe", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/binnis_wardrobe.png", aspectRatio: "49/12" },
	{ company_name: "cottonfab", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/cotton_fab.png", aspectRatio: "1" },
	{ company_name: "fashionjewel", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/fashion_jewel.png", aspectRatio: "1" },
	{ company_name: "nidhifashion", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/nidhi_fashion.png", aspectRatio: "11/4"},
	{ company_name: "fairdealtrader", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/fair_deal_trader.png", aspectRatio: "61/25" },
	{ company_name: "homerz", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/homerz.png", aspectRatio: "195/44" },
	{ company_name: "fargo", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/fargo.png", aspectRatio: "89/20" },
	{ company_name: "gopiprem", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/gopi_prem.png", aspectRatio: "85/56" },
	{ company_name: "jerseykart", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/jerseykart.png", aspectRatio: "245/48" },
	{ company_name: "mgsv", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/mgsv_brand.png", aspectRatio: "1/1"},
	{ company_name: "indianlinensaree", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/indianlinensaree.png", aspectRatio: "17/7" },
	{ company_name: "indiandecor", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/indian_decor.png", aspectRatio: "3/4"},
	{ company_name: "jeweltoz", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/jeweltoz.png", aspectRatio: "2/3"},

	// adding 2x to make it event
	// { company_name: "binniswardrobe", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/binnis_wardrobe.png",},
	// { company_name: "cottonfab", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/cotton_fab.png"},
	// { company_name: "fashionjewel", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/fashion_jewel.png"},
	// { company_name: "nidhifashion", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/nidhi_fashion.png"},
	// { company_name: "fairdealtrader", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/fair_deal_trader.png"},
	// { company_name: "homerz", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/homerz.png"},
	// { company_name: "fargo", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/fargo.png"},
	// { company_name: "gopiprem", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/gopi_prem.png"},
	// { company_name: "jerseykart", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/jerseykart.png"},
	// { company_name: "mgsv", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/mgsv_brand.png"},
	// { company_name: "indianlinensaree", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/indianlinensaree.png"},
	// { company_name: "indiandecor", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/indian_decor.png"},
	// { company_name: "jeweltoz", url: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/trusted-by-logos/jeweltoz.png"},
]
