import React from "react";
import { css } from "@emotion/react";

import WidgetContainer from "atoms/WidgetContainer";
import Text from "atoms/Text";
import Flexbox from "atoms/Flexbox";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";
import { GrowthConsultantItemProps } from "./types";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import growthConsultantFeatures from "constants/growthConsultantFeatures";
import Animated from "molecules/Animated";

const styles = {
	gcItemContainer: css`
		background-color: var(--color-dark-gray-neutral);
		border-radius: var(--spacing-md);
		padding: var(--spacing-xxl);
		cursor: pointer;

		position: relative;
		overflow: hidden;

		span {
			position: relative;
			z-index: 1;
		}

		&:hover div {
			opacity: 1;
			z-index: 0;
		}
	`,
	gcItemContainerXL: css`
		margin-left: var(--spacing-xxl);
	`,
	gcItemBackgroundLayer: css`
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: var(--color-primary-gradient);
		opacity: 0;

		transition: opacity 0.1s linear;
	`
}

const GrowthConsultantItem = (props: GrowthConsultantItemProps): JSX.Element => {
	return (
		<ResponsiveDiv
			css={styles.gcItemContainer}
			xl={styles.gcItemContainerXL}
			mb="lg"
		>
			<Text as="span" color="white" fontStyleGuide="body4">
				<span dangerouslySetInnerHTML={{__html: props.text}} />
			</Text>
			<ResponsiveDiv
				css={styles.gcItemBackgroundLayer}
				as="div"
			>
			</ResponsiveDiv>
		</ResponsiveDiv>
	)
}

export default () => {
	return (
		<WidgetContainer id="growth-consulting">
			<Flexbox direction="row" justify="space-between" align="center">
				<FlexboxItem colspan={24} colspanXL={12} mb="xxl">
					<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1}>
						<Text as="h4" fontStyleGuide="body2" fontStyleGuideXL="heading3" color="white" mb="sm" alignXL="left" align="center">
							<Text as="span" fontStyleGuide="body2" fontStyleGuideXL="heading3" primaryGradient={true}>
								Growth&nbsp;
							</Text>
							Consulting
						</Text>
					</Animated>
					<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.1}>
						<Text as="h2" color="white" fontStyleGuide="manrope-heading3" fontStyleGuideXL="manrope-heading2" mb="xxl" alignXL="left" align="center">
							Our Experts focus on your true profitability
						</Text>
					</Animated>
					<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.2}>
						<Text as="p" color="white" fontStyleGuide="body3" mb="lg" alignXL="left" align="center">
							Dedicated experts who help you sustain profitability & grow revenue by supporting you with
						</Text>
					</Animated>
				</FlexboxItem>
				<FlexboxItem colspan={24} colspanXL={12}>
					{growthConsultantFeatures.map((gcItem, index) => (
						<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.1 * index}>
							<GrowthConsultantItem text={gcItem} key={`gcitem-feature-${index}`} />
						</Animated>
					))}
				</FlexboxItem>
			</Flexbox>
		</WidgetContainer>
	)
}
