export default [
	{
		label: "Phase 1 (Month 1)",
		title: "Set Up",
		highlight: "",
		subtitle: "Website, ad account, ad campaigns, payment gateway, & pricing.",
	},
	{
		label: "Phase 2 (Month 2 & 3)",
		title: "Kickstart Growth",
		highlight: "",
		subtitle: "Optimise marketing campaigns to maximise ROI & reduce RTO.",
	},
	{
		label: "Phase 3 (Month 2 & 3)",
		title: "Achieve Profitability",
		highlight: ">40% of brands reach profitability",
		subtitle: "after an average spend of ₹42k.",
	},
	{
		label: "Phase 4 (Lifetime)",
		title: "Kickstart Growth",
		highlight: "Grow to >₹50L yearly revenue",
		subtitle: "as a sustainable brand.",
	},
]
