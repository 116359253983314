import React from "react";
import { css } from "@emotion/react";

import Text from "atoms/Text";
import Image from "atoms/Image";
import WidgetContainer from "atoms/WidgetContainer";
import Flexbox from "atoms/Flexbox";
import Button from "atoms/Button";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";
import Animated from "molecules/Animated";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import { useInView } from "react-intersection-observer";

//const heroImageUrl = `https://d2z53scj8veve3.cloudfront.net/shopdeck-home/d2c-brand-hero-1200.png`
const heroImageUrl = `https://d1311wbk6unapo.cloudfront.net/NushopWebsiteAsset2/tr:w-1200,f-webp,fo-auto/shopdeck-home/d2c-brand-hero-1200.png`;

const styles = {
	heroImageContainer: css`
		width: 100%;
		transform-style: preserve-3d;
		will-change: transform;

		transition: transform .5s ease-in-out;
		transform: scale3d(0.7,0.7,1);
	`,
	heroImageStyle: css`
		max-width: 100%;
		height: auto;
	`
}


// export default () => {
// 	const containerRef = React.useRef<HTMLElement>();
// 	const scrollHeightRef = React.useRef(0);
// 	const viewportHeightRef = React.useRef(0);
// 	const scrollDistancePercentWhenContainerEnteredViewportRef = React.useRef(-1);
// 	const containerHeightRef = React.useRef(0);


// 	const springScalePosition = useSpringValue(0.7 as number, {
// 		default: {
// 			immediate: true,
// 			reset: false
// 		},
// 		config: {
// 			tension: 120,
// 			friction: 14,
// 		},
// 	});

// 	React.useEffect(() => {
// 		// compute these values only on client and not on server
// 		// hence computing in useEffect
// 		scrollHeightRef.current = document.body.scrollHeight;
// 		viewportHeightRef.current = window.innerHeight;
// 		containerHeightRef.current = containerRef.current?.getBoundingClientRect().height || 0;
// 	}, []);


// 	const scrollProps = useScroll({
// 		// onRest: (value) => {
// 		// 	console.log('>>> value on rest: ', value);
// 		// },
// 		onRest: (value) => {
// 			requestFrame(() => {
// 				const scrollDistancePercent = value.value.scrollYProgress;
// 				const scrollHeight = scrollHeightRef.current;
// 				const viewportHeight = viewportHeightRef.current;
// 				const containerPositionFromTop = containerRef.current?.getBoundingClientRect().top || 0;
// 				const containerScrollHeightPercent = containerHeightRef.current / scrollHeight;

// 				const hasContainerEnteredViewport = containerPositionFromTop < viewportHeight;
// 				if(scrollDistancePercentWhenContainerEnteredViewportRef.current === -1  && hasContainerEnteredViewport) {
// 					scrollDistancePercentWhenContainerEnteredViewportRef.current = scrollDistancePercent;
// 				}

// 				const targetDistance = hasContainerEnteredViewport ? scrollDistancePercentWhenContainerEnteredViewportRef.current + containerScrollHeightPercent : 0;

// 				// this offset is basically how soon you want the animation to take place
// 				const offset = 0;
// 				const percentReached = hasContainerEnteredViewport ? (((scrollDistancePercent) / targetDistance) + offset) : 0

// 				// console.log(`
// 				// 	>>>> scrollDistancePercentWhenContainerEnteredViewportRef: ${scrollDistancePercentWhenContainerEnteredViewportRef.current}
// 				// 	>>>> containerScrollHeightPercent: ${containerScrollHeightPercent}
// 				// 	>>>> scrollDistancePercent: ${scrollDistancePercent}
// 				// 	>>>> targetDistance: ${targetDistance}
// 				// 	>>>> percentReached: ${percentReached}
// 				// `)

// 				if (springScalePosition.isAnimating) {
// 					springScalePosition.stop();
// 				}

// 				springScalePosition.start(
// 					Math.min(
// 						Math.max(
// 							percentReached, 0.7)
// 					, 1)
// 				)
// 			})
// 		},
// 		config: {
// 			tension: 0,
// 			friction: 0,
// 		},
// 	});


// 	return (
// 		<WidgetContainer id="run-your-d2c-brand">
// 			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1}>
// 				<Text as="h1" mb="xxl" fontStyleGuideXL="manrope-heading1" fontStyleGuide="manrope-heading4" color="white" align="center" wordWrap="break-word">
// 					Run Your D2C <br></br> Business&nbsp;
// 					<Text fontStyleGuideXL="manrope-heading1" fontStyleGuide="manrope-heading4" as="span" primaryGradient={true}>“Profitably”</Text>
// 				</Text>
// 			</Animated>
// 			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.2}>
// 				<Text as="p" mb="xxl" fontStyleGuide="body3" align="center" textWrap="wrap" color="gray-neutral">
// 					Your all-in-one stack to run profitable, <br></br>streamlined storefronts with ease
// 				</Text>
// 			</Animated>
// 			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.4}>
// 				<Flexbox direction="row" justify="center" align="center">
// 					<FlexboxItem colspan={"auto"} mb="xxl">
// 						<Button type="primary" to="/book-demo-form">Book a Free Demo</Button>
// 					</FlexboxItem>
// 				</Flexbox>
// 			</Animated>
// 			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.6}>
// 				<animated.div
// 					ref={containerRef as any}
// 					css={css`
// 						${styles.heroImageContainer};
// 					`}
// 					style={{
// 						// transition: "all",
// 						// scaleX: scrollProps.scrollYProgress.to(scrollP => { console.log('???? scrollP: ', scrollP); return Math.max(scrollP, 0.8) }),
// 						// scaleY: scrollProps.scrollYProgress.to(scrollP => { return Math.max(scrollP, 0.8) }),
// 						// scaleZ: 1,
// 						scaleX: springScalePosition,
// 						scaleY: springScalePosition
// 					}}>
// 					<Image
// 						css={css`
// 							${styles.heroImageStyle};
// 						`}
// 						src={heroImageUrl}
// 						alt="shopdeck-dashboard-hero-image"
// 						loading="lazy"
// 					/>
// 				</animated.div>
// 			</Animated>
// 		</WidgetContainer>
// 	)
// }


export default () => {
	const { ref: containerRef, inView, entry } = useInView({
		triggerOnce: false,
		threshold: 1
		//rootMargin: `${-50-(props.index*5)}% 0% ${-50-(props.index*5)}% 0%`,
		//rootMargin: `-50% 0% -50% 0%`
	});


	return (
		<WidgetContainer id="run-your-d2c-brand">
			<ResponsiveDiv ref={containerRef}>
				<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1}>
					<Text as="h1" mb="xxl" fontStyleGuideXL="manrope-heading1" fontStyleGuide="manrope-heading4" color="white" align="center" wordWrap="break-word">
						Run Your D2C <br></br> Business&nbsp;
						<Text fontStyleGuideXL="manrope-heading1" fontStyleGuide="manrope-heading4" as="span" primaryGradient={true}>“Profitably”</Text>
					</Text>
					{/* <Text color="white" align="center" fontStyleGuide="body3">{`Support of useInView: ${inView}`}</Text> */}
				</Animated>
			</ResponsiveDiv>
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.2}>
				<Text as="p" mb="xxl" fontStyleGuide="body3" align="center" textWrap="wrap" color="gray-neutral">
					Your all-in-one stack to run profitable, <br></br>streamlined storefronts with ease
				</Text>
			</Animated>
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.4}>
				<Flexbox direction="row" justify="center" align="center">
					<FlexboxItem colspan={"auto"} mb="xxl">
						<Button type="primary" to="/book-demo-form">Book a Free Demo</Button>
					</FlexboxItem>
				</Flexbox>
			</Animated>
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} delay={0.6}>
				<div
					css={css`
						${styles.heroImageContainer};
						${!inView && `transform: scale3d(1,1,1);`}
					`}>
					<Image
						css={css`
							${styles.heroImageStyle};
						`}
						src={heroImageUrl}
            width={1200}
            height={756}
						alt="shopdeck-dashboard-hero-image"
					/>
				</div>
			</Animated>
		</WidgetContainer>
	)
}
