import ResponsiveDiv from "atoms/ResponsiveDiv"
import WidgetContainer from "atoms/WidgetContainer"
import Animated from "molecules/Animated"
import { css } from "@emotion/react"
import Text from "atoms/Text";
import Flexbox from "atoms/Flexbox";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";
import founderDetails from "constants/founderDetails";
import { BackedByBestProps } from "./types";
import Image from "atoms/Image";
import backedByBestDetails from "constants/backedByBestDetails";
import React from "react";


const styles = {
	backedByBestCard: css`
		border-radius: var(--spacing-lg);
		background-color: var(--color-white);
		padding: var(--spacing-xxl) var(--spacing-xl);
	`,
	backedByBestImage: css`
		width: 100%;
		height: auto;
		height: 40px;
	`
}

const BackedByBestCard = (props: BackedByBestProps) => {
	return (
		<Animated
			animation="zoomIn"
			duration={0.5}
			iteration={1}
			timingFunction="ease"
			trigger={"inview"}>
			<ResponsiveDiv css={styles.backedByBestCard} ml="xs" mr="sm" mb="md">
				<Image
					css={styles.backedByBestImage}
					src={props.imageUrl}
					loading="lazy"
          alt={`backed-by-best-${props.imageUrl}`}
				/>
			</ResponsiveDiv>
		</Animated>
	)
}


export default () => {
	return (<WidgetContainer>
		<Animated animation="fadeInUp" duration={1} timingFunction="ease" iteration={1} trigger="inview">
			<Text fontStyleGuide="heading2" color="primary" align="center">
				Backed &nbsp;
				<Text as="span" fontStyleGuide="heading2" color="white">by the Best</Text>
			</Text>
		</Animated>

		<Animated animation="fadeInUp" duration={1} timingFunction="ease" iteration={1} trigger="inview" delay={0.2}>
			<Flexbox direction="row" justify="space-between" align="stretch" mt="2xl">
				<FlexboxItem colspanXL={6} colspan={12}>
					<BackedByBestCard imageUrl={backedByBestDetails.elevation.imageUrl} />
				</FlexboxItem>
				<FlexboxItem colspanXL={6} colspan={12}>
					<BackedByBestCard imageUrl={backedByBestDetails.bessemer.imageUrl} />
				</FlexboxItem>
				<FlexboxItem colspanXL={6} colspan={12}>
					<BackedByBestCard imageUrl={backedByBestDetails.chiratae.imageUrl} />
				</FlexboxItem>
				<FlexboxItem colspanXL={6} colspan={12}>
					<BackedByBestCard imageUrl={backedByBestDetails.venture.imageUrl} />
				</FlexboxItem>
			</Flexbox>
		</Animated>

	</WidgetContainer>
	)
}
