import React from "react";
import { css } from "@emotion/react";
import Flexbox from "atoms/Flexbox";
import { NavbarProps } from "./types";
import Button from "atoms/Button";
import HashLink from "atoms/HashLink";
import Link from "atoms/Link";
import scrollIntoView from "utils/scrollIntoView";


const navItems = [
	{ label: "Home", url: "/#run-your-d2c-brand", type: "link", onClick: () => window.scrollTo(0,0) },
	{ label: "Offerings", url: "/#our-offerings", type: "link" },
	{ label: "About", url: "/#founders", type: "link" },
	{ label: "Testimonials", url: "/#testimonials", type: "link" },
	{ label: "Pricing", url: "/#pricing", type: "link" },
	{ label: "Careers", url: "/careers", type: "link", target: "_blank" },
	{ label: "Log in", url: "https://pro.shopdeck.com/login", type: "link" },
	{ label: "Book a Free Demo", url: "/book-demo-form", type: "button" }
]

const styles = {
	navItemHolderDesktop: css`
		padding-left: var(--spacing-sm);
	`,
	navItemDesktop: css`
		padding: var(--spacing-md) var(--spacing-lg);
	`,
	navItemHolderMobile: css`
		padding: var(--spacing-xs) 0;
	`,
	navItemMobile: css`
		padding: var(--spacing-md) var(--spacing-lg);
	`,
}

export default (props: NavbarProps): JSX.Element => {
	const { type } = props;

	if (type === 'desktop') return (
		<Flexbox
			as="nav"
			justify="flex-start"
			align="center"
			direction="row"
		>
			{navItems.map(
				item => (
					<React.Fragment key={item.label}>
						{item.type === "link" ? (<div
							css={styles.navItemHolderDesktop}
						>
							<div css={styles.navItemDesktop}>
								<HashLink
									to={item.url}
									target={item.target}
									onClick={item.onClick}
								>
									<p>{item.label}</p>
								</HashLink>
							</div>
						</div>) : (
							<Button to={item.url} type="primary">
								{item.label}
							</Button>
						)}
					</React.Fragment>
				)
			)}
		</Flexbox>
	)

	if (type === 'mobile') return (

		<Flexbox
			as="nav"
			justify="flex-start"
			align="flex-start"
			direction="column"
		>
			{navItems.filter(item => item.type === 'link').map(
				item => (
					<React.Fragment key={item.label}>
						{item.type === "link" ? (<div
							css={styles.navItemHolderMobile}
							key={item.label}
						>
							<div css={styles.navItemMobile}>
								<HashLink
									to={item.url}
									target={item.target}
									onClick={item.onClick}
								>
									<p>{item.label}</p>
								</HashLink>
							</div>
						</div>) : (
							<Button to={item.url} type="primary">
								{item.label}
							</Button>
						)}
					</React.Fragment>
				)
			)}
		</Flexbox>
	)

	return <></>;
}
