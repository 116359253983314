
import React from "react"
import { css } from "@emotion/react"

import WidgetContainer from "atoms/WidgetContainer";
import Text from "atoms/Text";
import Image from "atoms/Image";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import Flexbox from "atoms/Flexbox";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";
import Animated from "molecules/Animated";
import { FeatureItemProps } from "./types";
import weHelpYouSucceedLogos from "constants/weHelpYouSucceedLogos";


const features = [
	{ iconUrl: weHelpYouSucceedLogos.winwin, title: "Win-Win Approach", subtitle: "No retainer fee" },
	{ iconUrl: weHelpYouSucceedLogos.allinone, title: "All-In-One Stack", subtitle: "Don't bother about integrations with costly third-party apps" },
	{ iconUrl: weHelpYouSucceedLogos.profitfocused, title: "Profit-Focused", subtitle: "Consulting expertise beyond marketing to enable profitability" },
]

const FeatureItem = (props: FeatureItemProps) => (
	<FlexboxItem
		colspanXL={7.70}
		colspan={24}
		mb="xxxl"
		css={css`
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		`}
	>
		<Animated
			animation="zoomIn"
			duration={0.7}
			iteration={1}
			timingFunction="ease"
			trigger={"inview"}
			passedCSS={css`
				border-radius: var(--spacing-xl);
				background-color: var(--color-dark-gray);
				padding: calc(12 * var(--spacing-xs));
				height: 100%;
			`}
		>
			<Animated
				animation="fadeInUp"
				duration={0.5}
				iteration={1}
				timingFunction="ease"
				trigger={"inview"}
				delay={0.2}
			>
				<ResponsiveDiv
					css={css`
						display: block;
						width: 164px;
						height: 72px;
						margin: 0 auto;
						background-image: url('${props.iconUrl}');
						background-position: center;
						background-size: cover;
						transform: scale(1.5);
					`}
				/>
			</Animated>
			<Text fontStyleGuide="manrope-heading5" color="white" align="center" mt="xxl">
				{props.title}
			</Text>
			<Text fontStyleGuide="body4" color="gray-neutral" mt="lg" align="center">
				{props.subtitle}
			</Text>
		</Animated>
	</FlexboxItem>
)

export default () => {
	return (
		<WidgetContainer id="we-help-you-succeed">
			<Animated animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} trigger="inview">
				<Text as="h2" fontStyleGuide="heading3" color="white" align="center">
					We Help You&nbsp;
					<Text as="span" fontStyleGuide="heading3" primaryGradient={true}>Succeed</Text>
				</Text>
			</Animated>
			<Animated animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} trigger="inview" delay={0.1}>
				<Text fontStyleGuide="body3" color="gray-neutral" align="center" mt="md">
					The only company that works for your profitability!
				</Text>
			</Animated>
			<Flexbox mt="2xl" direction="row" justify="space-between" align="stretch">
				{features.map((feature) => (
					<FeatureItem
						key={`unique-feature-item-${feature.title}`}
						title={feature.title}
						subtitle={feature.subtitle}
						iconUrl={feature.iconUrl}
					/>
				))}
			</Flexbox>
		</WidgetContainer>
	)
}
