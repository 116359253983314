import { IconProps } from "./types";

export default (props: IconProps): JSX.Element => {
	const { width = 24, height = 24 } = props;
	return (
		<svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="Socials">
				<path id="Button" d="M23.4982 6.37364C23.2216 5.34039 22.4096 4.5284 21.3763 4.25181C19.5055 3.75 12 3.75 12 3.75C12 3.75 4.49456 3.75 2.62364 4.25181C1.59039 4.5284 0.778399 5.34039 0.501811 6.37364C1.50211e-07 8.24456 0 12.1504 0 12.1504C0 12.1504 1.50211e-07 16.0562 0.501811 17.9272C0.778399 18.9604 1.59039 19.7724 2.62364 20.0489C4.49456 20.5508 12 20.5508 12 20.5508C12 20.5508 19.5055 20.5508 21.3763 20.0489C22.4096 19.7724 23.2216 18.9604 23.4982 17.9272C24 16.0562 24 12.1504 24 12.1504C24 12.1504 23.998 8.24456 23.4982 6.37364Z" fill="#FF0000" />
				<path id="Play" d="M9.59766 15.75L15.8328 12.1504L9.59766 8.55078V15.75Z" fill="white" />
			</g>
		</svg>
	)
}
