import { css } from "@emotion/react";

import WidgetContainer from "atoms/WidgetContainer";
import Text from "atoms/Text";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import Flexbox from "atoms/Flexbox";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";
import Image from "atoms/Image";
import { FounderCardProps } from "./types";
import LinkedIn from "icons/LinkedIn";
import Link from "atoms/Link";

import Animated from "molecules/Animated";
import founderDetails from "constants/founderDetails";
import React from "react";


const styles = {
	founderCard: css`
		border-radius: var(--spacing-md);
		background-color: var(--color-dark-gray);
		padding: var(--spacing-xxl) var(--spacing-xl);
		height: 100%;
	`,
	founderCardSpacer: css`
		padding: 0 var(--spacing-sm);
		height: 100%;
	`,
	founderCardSpacerXL: css`
		padding: 0 var(--spacing-xl);
	`,
	founderImage: css`
		width: 104px;
    height: 104px;

		@media(min-width: 1200px) {
			width: 160px;
    	height: 160px;
		}
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
	`
}

const FounderCard = (props: FounderCardProps) => {
	return (
		<ResponsiveDiv css={styles.founderCardSpacer} xl={styles.founderCardSpacerXL}>
			<Flexbox
				direction="column"
				justify="space-between"
				align="center"
				css={styles.founderCard}
			>
				<Image
					css={styles.founderImage}
					src={props.imageUrl}
					loading="eager"
					alt={`founder-${props.name}`}
				/>

				<Text fontStyleGuideXL="heading6" fontStyleGuide="body3" color="white" align="center" mt="lg">
					{props.name}
				</Text>
				<Text fontStyleGuide="body4" color="gray-neutral" align="center" mt="xs" mb="sm">
					Co-Founder, ShopDeck
				</Text>
				<Link to={props.linkedInUrl} target="_blank" aria-label={`LinkedIn Profile of ${props.name}`}>
					<LinkedIn />
				</Link>

			</Flexbox>
		</ResponsiveDiv>
	)
}

export default () => {
	return (
		<WidgetContainer id="founders">
			<Animated animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} trigger="inview">
				<Text as="h2" fontStyleGuide="heading4" primaryGradient={true} align="center">
					Founders
				</Text>
			</Animated>
			<Flexbox direction="row" justify="center" align="stretch" mt="2xl">
				<FlexboxItem colspanXL={9} colspan={12} mb="md">
					<FounderCard name={founderDetails.rishabh.name} imageUrl={founderDetails.rishabh.imageUrl} linkedInUrl={founderDetails.rishabh.linkedInUrl} />
				</FlexboxItem>
				<FlexboxItem colspanXL={9} colspan={12} mb="md">
					<FounderCard name={founderDetails.harmin.name} imageUrl={founderDetails.harmin.imageUrl} linkedInUrl={founderDetails.harmin.linkedInUrl} />
				</FlexboxItem>
			</Flexbox>

		</WidgetContainer>
	);
}
