import React, { PropsWithChildren } from "react";
import { css } from "@emotion/react";
import { Link, LinkProps } from "react-router-dom";
import { SDLinkProps } from "./types";
import { FontStyleGuide } from "atoms/types";

const linkStyle = css`
	color: var(--color-white);
	font-size: var(--font-size-body5);
	font-weight: var(--font-weight-body5);
	line-height: var(--font-lineheight-body5);
	transition: color .35s;

	&:hover {
		color: var(--color-primary);
	}
`;

export default (props: PropsWithChildren<SDLinkProps>): JSX.Element => {
	return (
		<Link
			css={css`
				${linkStyle};
				${props.underline && `text-decoration: underline;`}
			`}
			{...props}
		>
			{props.children}
		</Link>
	)
}
