import React, { PropsWithChildren } from "react";
import { useInView } from "react-intersection-observer";
import { css } from "@emotion/react";

import Text from "atoms/Text";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import WidgetContainer from "atoms/WidgetContainer";
import Flexbox from "atoms/Flexbox";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";
import yourJourneyDetails from "constants/yourJourneyDetails";


import Animated from "molecules/Animated";
import { JourneyCardProps, JourneyCardWrapperProps } from "./types";

const styles = {
	journeyCard: css`
		background-color: var(--color-dark-gray);
		border-radius: var(--spacing-md);
		padding: calc(8 * var(--spacing-xs));
		overflow: hidden;
		position: relative;
		will-change: transform;
		transition: all .1s linear;
		transform: scale3d(.9,.9,1);
	`,
	journeyCardXL: css`
		transform: scale3d(1,1,1);
	`,
	journeyCardExpanded: css`
		transform: scale3d(1,1,1);
		span {
			opacity: 1;
		}
	`,
	journeyCardExpandedXL: css`
		transform: scale3d(1.05,1.05,1);
		span {
			opacity: 1;
		}
	`,
	journeyCardContentBox: css`
		z-index: 1;
		position: relative;
	`,
	blueLayer: css`
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background: var(--color-primary-gradient);
		opacity: 0;

		transition: opacity 0.1s linear;
	`,
	journeyMapStage: css`
		position: relative;
		padding-top: 32px;
	`,
	journeyCardRowWrapper: css`
		margin-bottom: 120px;
	`,
	journeyDividerWrapper: css`
		position: absolute;
		left: 50%;
		width: 50px;
		transform: translateX(-50%);
		top: calc(32px + 100px); // top padding + (height of card/2)
		bottom: 90px;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	`,
	journeyDivider: css`
		border-radius: var(--spacing-2xl);
		background-image: var(--color-primary-gradient);
		width: 2px;
		height: 100%;
		display: block;
	`,
	journeyBead: css`
		border-radius: 50%;
		background-color: var(--color-primary);
		width: 24px;
		height: 24px;
		position: absolute;
	`
}

const JourneyCard = (props: JourneyCardProps): JSX.Element => {
	const { ref, inView } = useInView({
		triggerOnce: false,
		//rootMargin: `${-50-(props.index*5)}% 0% ${-50-(props.index*5)}% 0%`,
		rootMargin: `-50% 0% -40% 0%`
	});

	return (
		<ResponsiveDiv
			as="article"
			css={css`
				${styles.journeyCard};
				${inView && styles.journeyCardExpanded};
			`}
			xl={css`
				${styles.journeyCardXL};
				${inView && styles.journeyCardExpandedXL};
				${props.direction === 'left' ? `transform-origin: top right;` : `transform-origin: top left;`}
			`}
			ref={ref}
		>
			<ResponsiveDiv as="div" css={styles.journeyCardContentBox}>
				<Text as="p" fontStyleGuide="body4" color={inView ? 'black' : 'white'} primaryGradient={inView ? false : true} mb="lg">{props.label}</Text>
				<Text as="p" fontStyleGuide="body2" color="white" mb="lg">{props.title}</Text>
				<Text as="p" fontStyleGuide="body4" color="white">
					{props.highlight && <Text as="span" fontStyleGuide="body4" color="green200">{props.highlight}&nbsp;</Text>}
					{props.subtitle}
				</Text>
			</ResponsiveDiv>
			<ResponsiveDiv as="span" css={styles.blueLayer} />
		</ResponsiveDiv>
	)
}

const JourneyCardWrapper = (props: PropsWithChildren<JourneyCardWrapperProps>): JSX.Element => {
	return (
		<Flexbox
			css={css`
				${styles.journeyCardRowWrapper};
				${props.isLast && `margin-bottom: 0;`}
			`}
			direction="row"
			justify={props.direction === "left" ? "flex-start" : "flex-end"}
			align="center"
		>
			<FlexboxItem colspan={24} colspanXL={12}>
				<Flexbox direction="row" justify={props.direction === "left" ? "flex-start" : "flex-end"} align="center">
					<FlexboxItem colspan={24} colspanXL={20}>
						{props.children}
					</FlexboxItem>
				</Flexbox>
			</FlexboxItem>
		</Flexbox>
	)
}

const JourneyDivider = () => {
	return (
		<ResponsiveDiv
			css={styles.journeyDividerWrapper}
			as="div"
		>
			<ResponsiveDiv as="span" css={styles.journeyDivider} />
			<ResponsiveDiv
				as="span"
				css={css`
					${styles.journeyBead};
					top: 0px;
				`}
			/>
			<ResponsiveDiv
				as="span"
				css={css`
					${styles.journeyBead};
					top: calc(120px + 200px);	// 1 * (card bottom margin + bottom height)
					transform: translateY(-50%);
				`}
			/>
			<ResponsiveDiv
				as="span"
				css={css`
					${styles.journeyBead};
					top: calc(240px + 400px);	// 2 * (card bottom margin + bottom height)
					transform: translateY(-50%);
				`}
			/>
			<ResponsiveDiv
				as="span"
				css={css`
					${styles.journeyBead};
					bottom: 0;
				`}
			/>
		</ResponsiveDiv>
	)
}

export default () => {
	return (
		<WidgetContainer>
			<Animated animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} trigger="inview">
				<Text fontStyleGuide="heading4" color="white" align="center" mb="2xl">
					What Will &nbsp;
					<Text as="span" fontStyleGuide="heading4" primaryGradient={true}>Your Journey&nbsp;</Text>
					Look Like?
				</Text>
			</Animated>
			<Flexbox direction="row" justify="center" align="center">
				<FlexboxItem
					colspan={24}
					colspanXL={22}
					css={styles.journeyMapStage}
				>
					<JourneyDivider />
					{yourJourneyDetails.map((yourJourney, index) => (
						<JourneyCardWrapper
							isLast={index === (yourJourneyDetails.length - 1)}
							direction={index % 2 === 0 ? "right" : "left"}
							key={`your-journey-card-${yourJourney.label}-${index}`}
						>
							<JourneyCard direction={index % 2 === 0 ? "right" : "left"} index={index} title={yourJourney.title} label={yourJourney.label} highlight={yourJourney.highlight} subtitle={yourJourney.subtitle} />
						</JourneyCardWrapper>
					))}
				</FlexboxItem>
			</Flexbox>
		</WidgetContainer>
	)
}
