export default {
	elevation: {
		imageUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/backed-by-best/elevation.svg",
	},
	bessemer: {
		imageUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/backed-by-best/bessemer.svg",
	},
	chiratae: {
		imageUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/backed-by-best/chiratae.svg",
	},
	venture: {
		imageUrl: "https://d2z53scj8veve3.cloudfront.net/shopdeck-home/backed-by-best/venture-highway.svg",
	}
}
