import { SpinnerProps } from "./types"
import { css } from "@emotion/react";

export default (props: SpinnerProps) => {
	const { width = 60, height = 60 } = props;
	return (
		<img
			css={css`
				object-fit: contain;
			`}
			src="https://d2z53scj8veve3.cloudfront.net/shopdeck-home/loader-200px.gif"
			width={width}
			height={height}
		/>
	)
}
