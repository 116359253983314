import WidgetContainer from "atoms/WidgetContainer";
import Text from "atoms/Text";

import Animated from "molecules/Animated";
import { css } from "@emotion/react";
import React from "react";

export default () => {
	return (
		<WidgetContainer id="our-philosophy">
			<Animated animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} trigger="inview">
				<Text fontStyleGuide="heading4" color="white" align="center">
					Our &nbsp;
					<Text as="span" fontStyleGuide="heading4" primaryGradient={true}>Philosophy</Text>
				</Text>
			</Animated>
			<Animated animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} trigger="inview" delay={0.1}>
				<Text mt="2xl" textWrap="balance" fontStyleGuide="heading6" fontStyleGuideXL="manrope-heading3" color="white" align="center">
					Brand owners should focus on creating world class products—not channel optimization
				</Text>
			</Animated>
		</WidgetContainer>
	)
}
