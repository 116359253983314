import React from "react";
import { css } from "@emotion/react";

import WidgetContainer from "atoms/WidgetContainer";
import Text from "atoms/Text";
import Image from "atoms/Image";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import companyLogos from "constants/companyLogos";
import Flexbox from "atoms/Flexbox";
import { TrustedByItemProps } from "./types";
import Animated from "molecules/Animated";

const styles = {
	trustedByItem: css`
		padding: var(--spacing-lg) var(--spacing-xxl);
		background-color: var(--color-dark-gray);
		border-radius: var(--spacing-lg);
		display: flex;
		align-items: center;
		justify-content: center;
		height: 90px;
		min-width: 180px;
		width: fit-content;
		max-width: 276px;
		overflow: hidden;
	`,
	trustedByItemImage: css`
		max-height: -webkit-fill-available;
		object-fit: contain;
	`,
	trustedByItemsContainer: css`
		display: grid;
		width: 100%;
		grid-auto-columns: 1fr;
		grid-template-columns: repeat(14, 1fr);
		grid-template-rows: auto;
		grid-row-gap: var(--spacing-md);
	`,
	trustedByItemScrollerWrapper: css`
		overflow: hidden;
		position: relative;

		&:hover div[data-animation] {
			animation-play-state: paused;
		}
	`,
	leftMask: css`
		position: absolute;
		background-image: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0.1) 81%);
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 70px;
	`,
	rightMask: css`
		position: absolute;
		background-image: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(0,0,0,0.1) 81%);
		top: 0;
		bottom: 0;
		right: -1px;
		z-index: 1;
		width: 70px;
		transform: rotate(180deg);
	`
}


const TrustedByItem = (props: TrustedByItemProps) => {
	return (
		<ResponsiveDiv
			css={css`
				padding-right: var(--spacing-md);
			`}
			xl={css`
				padding: 0 calc(8 * var(--spacing-xs));
			`}
		>
			<ResponsiveDiv
				css={styles.trustedByItem}
			>
				<Image
					css={css`
						${styles.trustedByItemImage};
						aspect-ratio: ${props.aspectRatio};
					`}
					src={props.url}
					alt={`shopdeck-affliate-${props.companyName}`}
					loading="lazy"
				/>
			</ResponsiveDiv>
		</ResponsiveDiv>
	)
}

export default () => {
	return (
		<WidgetContainer id="trusted-by-brands">
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="linear" iteration={1}>
				<Text mb="2xl" as="h4" fontStyleGuide="body3" color="white" align="center">Trusted By 5000+ brands</Text>
			</Animated>
			<Animated trigger="inview" animation="fadeInUp" duration={0.5} timingFunction="linear" iteration={1} delay={0.2}>
				<ResponsiveDiv
					css={styles.trustedByItemScrollerWrapper}
				>
					<ResponsiveDiv as="span" css={styles.leftMask}></ResponsiveDiv>
					<Flexbox direction="row" justify="space-around" wrap="nowrap" align="center">
						<Animated trigger="auto" animation="slideOutLeft" animationOnHover="pause" duration={40} iteration={"infinite"} timingFunction="linear">
							<ResponsiveDiv css={styles.trustedByItemsContainer}>
								{companyLogos.map(company => (
									<TrustedByItem key={`row1-${company.company_name}-first`} aspectRatio={company.aspectRatio} url={company.url} companyName={company.company_name} />
								))}
							</ResponsiveDiv>
						</Animated>
						<Animated trigger="auto" animation="slideOutLeft" animationOnHover="pause" duration={40} iteration={"infinite"} timingFunction="linear">
							<ResponsiveDiv css={styles.trustedByItemsContainer}>
								{companyLogos.map(company => (
									<TrustedByItem key={`row1-${company.company_name}-second`} aspectRatio={company.aspectRatio} url={company.url} companyName={company.company_name} />
								))}
							</ResponsiveDiv>
						</Animated>
					</Flexbox>
					<ResponsiveDiv
						xl={css`
							display: none;
						`}
						mt="md"
					>
						<Flexbox direction="row" justify="space-around" wrap="nowrap" align="center">
							<Animated trigger="auto" animation="slideOutLeft" animationOnHover="pause" duration={40} iteration={"infinite"} timingFunction="linear">
								<ResponsiveDiv css={styles.trustedByItemsContainer}>
									{companyLogos.map(c => c).reverse().map(company => (
										<TrustedByItem key={`row2-${company.company_name}-first`} aspectRatio={company.aspectRatio} url={company.url} companyName={company.company_name} />
									))}
								</ResponsiveDiv>
							</Animated>
							<Animated trigger="auto" animation="slideOutLeft" animationOnHover="pause" duration={40} iteration={"infinite"} timingFunction="linear">
								<ResponsiveDiv css={styles.trustedByItemsContainer}>
									{companyLogos.map(c => c).reverse().map(company => (
										<TrustedByItem key={`row2-${company.company_name}-second`} aspectRatio={company.aspectRatio} url={company.url} companyName={company.company_name} />
									))}
								</ResponsiveDiv>
							</Animated>
						</Flexbox>
					</ResponsiveDiv>
					<ResponsiveDiv as="span" css={styles.rightMask}></ResponsiveDiv>
				</ResponsiveDiv>
			</Animated>
		</WidgetContainer>
	)
}
